export const GAME_TITLE = process.env.REACT_APP_GAME_NAME!

export const WIN_MESSAGES = ['Igerrez!']
export const GAME_COPIED_MESSAGE = 'Urar agi yura'
export const NOT_ENOUGH_LETTERS_MESSAGE = 'Drus isekkilen'
export const WORD_NOT_FOUND_MESSAGE = 'Awal ulac it'
export const HARD_MODE_ALERT_MESSAGE =
  'Addem Urar i waɛren si tazwara!'
export const HARD_MODE_DESCRIPTION =
  'assekilen i di vanen ilaq adilin g awal i di tedun'
export const HIGH_CONTRAST_MODE_DESCRIPTION = 'a tettwennaɛ tizri'
export const CORRECT_WORD_MESSAGE = (solution: string) =>
  `Awal d wagi ${solution}`
export const WRONG_SPOT_MESSAGE = (guess: string, position: number) =>
  `Ilaq adawiḍ ${guess} g wemḍiqis ${position}`
export const NOT_CONTAINED_MESSAGE = (letter: string) =>
  `Awal ilaq a di sɛu ${letter}`
export const ENTER_TEXT = 'Akcem'
export const DELETE_TEXT = 'Akes'
export const STATISTICS_TITLE = 'Statistics'
export const GUESS_DISTRIBUTION_TEXT = 'Tafelwit nw urar agi (Tableau)'
export const NEW_WORD_TEXT = 'Awal amaynut'
export const SHARE_TEXT = 'Zuzer'
export const TOTAL_TRIES_TEXT = 'Achal tikwal'
export const SUCCESS_RATE_TEXT = 'Achal i debiḍ'
export const CURRENT_STREAK_TEXT = 'Urar agi'
export const BEST_STREAK_TEXT = 'Urar sumata'
