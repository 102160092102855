export const WORDS = [
'acḥal',
'acimi',
'acraw',
'acruf',
'acrur',
'acudu',
'acufu',
'adfel',
'aḍɣaɣ',
'adɣes',
'adrar',
'adrim',
'adrum',
'afḍis',
'afrag',
'afran',
'afrid',
'avran',
'afrux',
'agafa',
'aɣmis',
'agraw',
'agdud',
'agris',
'aɣmis',
'aɣriv',
'aɣrum',
'aɣyul',
'aɣvel',
'aɣuru',
'agzam',
'aḥlil',
'aḥvul',
'ajrad',
'akken',
'akniw',
'aksum',
'akufi',
'aldun',
'alɣem',
'allaɣ',
'allen',
'almad',
'alqam',
'amalu',
'amaru',
'amcic',
'amcum',
'amdan',
'amḍiq',
'amger',
'amɣar',
'amɣid',
'amjaḥ',
'amkan',
'amnar',
'amnay',
'amrah',
'amrar',
'amrir',
'amsad',
'amzun',
'amzur',
'annar',
'ansuf',
'anṭaḍ',
'anẓaḍ',
'anzer',
'anẓar',
'anyir',
'aqcic',
'aqfal',
'aqeru',
'aqjun',
'aqrah',
'aqwir',
'aqvuc',
'araji',
'arfad',
'arkaḍ',
'arqaq',
'artal',
'argaz',
'arqam',
'arway',
'arzef',
'arẓaẓ',
'asafu',
'asaku',
'asaru',
'asefk',
'asfel',
'asɣar',
'askaf',
'aslaɣ',
'assif',
'asuli',
'asuki',
'aswal',
'aswir',
'aṭṭan',
'avlaḍ',
'avrid',
'avsis',
'avẓiẓ',
'avudu',
'avzim',
'awraɣ',
'awren',
'awqas',
'awtul',
'axjiḍ',
'aylaw',
'ayɣar',
'azeka',
'aẓeka',
'azger',
'azrar',
'azref',
'azrem',
'aẓɣal',
'aẓeṭa',
'ččina',
'cfaya',
'cvaha',
'cwari',
'cituḥ',
'cetwa',
'dela3',
'davex',
'felgu',
'fiḥel',
'furar',
'fudaɣ',
'fukaɣ',
'iclem',
'idles',
'iḍeli',
'iɣrem',
'iɣzer',
'ifrax',
'ifrir',
'ikkil',
'ikniw',
'inisi',
'inzan',
'inzer',
'inziz',
'iɣisi',
'irden',
'inzan',
'isali',
'islan',
'itran',
'itvir',
'iwata',
'ixmir',
'kunwi',
'ladɣa',
'lafɛa',
'lazuq',
'lexla',
'lɛarc',
'lḥamu',
'mazal',
'meden',
'melmi',
'mucaɛ',
'nadam',
'qares',
'qavel',
'seksu',
'sɛaya',
'siwaḍ',
'siwel',
'sraya',
'ṣrima',
'susem',
'ṣuḍaɣ',
'tafat',
'taɣat',
'taɣma',
'takna',
'taksa',
'tamda',
'tamẓi',
'targa',
'tarwa',
'tenwa',
'terfa',
'terwi',
'tenza',
'terza',
'tasga',
'tawla',
'tayri',
'tedha',
'tekfa',
'teɣzi',
'teldi',
'telha',
'tezdi',
'tezwi',
'tidet',
'tiɛad',
'tiɣri',
'tikci',
'tikli',
'tiqar',
'tiqit',
'times',
'timiṭ',
'tirga',
'tirni',
'tirza',
'tixsi',
'tufra',
'tugdi',
'tuqna',
'tufɣa',
'tusda',
'tusut',
'uccen',
'vaḍna',
'ucmit',
'ungif',
'urfan',
'ussan',
'yakan',
'yedha',
'yekfa',
'yeldi',
'yelha',
'yerfa',
'yerna',
'yerwa',
'yerza',
'yenwa',
'yenza',
'yerwi',
'yemma',
'yezwi',
'yiḍes',
'yiwen',
'yiwet',
'yulyu',
'yunyu',
'zayla',
'ẓiẓen',
]
